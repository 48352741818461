<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
    </template>
    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">mdi-book</v-icon> {{ $store.state.pageTitle }}
      </div>
      <v-divider></v-divider>
        <v-select
          v-model="selectedStudent"
          :items="students"
          item-text="firstname"
          item-value="id"
          label="Pilih Siswa"
          return-object
          class="mx-2"
          @change="setSelectedStudent"
        ></v-select>
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"
        v-if="loading"
      ></v-skeleton-loader>

      <v-expansion-panels v-model="panel" accordion multiple>
        <v-expansion-panel v-for="(d, x) in myData" :key="x" class="border-0">
          <v-expansion-panel-header
            >Kelas {{ d.classroom.grade }}
            {{ d.classroom.subgrade }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div v-for="(s, i) in d.classroom.schedule" :key="i">
              <div class="text-h5 ml-4 mb-2" style="width: 100%">
                <v-icon color="blue">mdi-calendar-multiple-check</v-icon>
                {{ s.name }}
              </div>
              <v-divider></v-divider>
              <div class="py-4">
                <v-list denase height="100%" width="100%" class="mx-0">
                  <v-list-item-group color="primary">
                    <v-list-item v-for="(sd, ii) in s.data" :key="ii" two-line>
                      <v-list-item-avatar class="d-flex align-start">
                        <v-avatar color="blue" size="36">
                          <span class="white--text text-h6">{{
                            sd.avatar_text
                          }}</span>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="sd.subjects_name"
                        ></v-list-item-title>

                        <v-row>
                          <v-col>
                            <v-list-item-subtitle
                              v-text="sd.teacher_fullname"
                            ></v-list-item-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <p class="text-body-2 my-0">
                              {{ sd.time_start }} - {{ sd.time_end }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "SubjectsSchedule",
  components: { DefaultLayout },

  data: () => ({
    myData: [],
    panel: [0],
    loading: null,
    students: [],
    selectedStudent: {},
  }),

  methods: {
    async getList() {
      
      this.loading = true;
      var data = {
        params: {
          student_id: this.selectedStudent.id
        }
      }
      await this.$axios.get("subjects/schedule", data).then((res) => {
        this.loading = false;
        if (res.data.status == "success") {
          this.myData = res.data.data;

          console.log(res.data);
        } else {
          this.myData = [];
        }
      });
    },
    setSelectedStudent(){
      this.$store.state.selectedStudent = this.selectedStudent
      this.getList()
    },
    getChildren() {
      this.loading = true;
      this.$axios.get("student/mychild").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.students = res.data.data.students;
        } else {
          this.students = [];
        }
        this.loading = false;
      });
    },
  },

  mounted() {
    this.selectedStudent = this.$store.state.selectedStudent
    this.getList();
    this.getChildren();
    this.setPageTitle("Jadwal Pelajaran", "mdi-book");
  },
};
</script>